@charset "UTF-8";
/*
@File: Exolot - Multipurpose Landing Page HTML5 Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

==== INDEX PAGE CSS

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - Subscribe Area CSS
** - Welcome Area CSS
** - About Area CSS
** - Services Area CSS
** - Team Area CSS
** - Portfolio Area CSS
** - Features Area CSS
** - Feedback Area CSS
** - Blog Area CSS
** - Work With Us Area CSS
** - Partner Area CSS
** - Contact Area CSS
** - Footer Area CSS
** - Right Side Modal CSS
** - Go Top CSS

*/
/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");
@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i");
html,
#root,
.App {
  height: 100%; }

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-display: "Poppins", sans-serif; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.p,
h1,
h2,
h3,
h4,
h5,
h6 p {
  font-family: "Ubuntu", sans-serif; }

.d-table {
  width: 100%;
  height: 100%; }

.d-table-cell {
  vertical-align: middle; }

.mb-10 {
  margin-bottom: 10px; }

.mb-20 {
  margin-bottom: 20px; }

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.pt-40 {
  padding-top: 40px; }

.w-100p {
  width: 100%; }

.h-100p {
  height: 100%; }

a {
  display: inline-block;
  outline: 0 !important;
  text-decoration: none !important;
  transition: 0.4s; }

p {
  font-weight: 300;
  font-size: 15px;
  line-height: 1.8;
  color: #999999; }

img {
  max-width: 100%; }

.bg-f9f9f9 {
  background: #f9f9f9; }

/*btn*/
.btn {
  font-weight: 600;
  font-size: 15px;
  border: none;
  padding: 10px 33px;
  line-height: 1.5;
  text-transform: capitalize;
  border-radius: 50px; }
  .btn:focus {
    outline: 0; }

button:focus {
  outline: 0; }

.btn-primary {
  color: #ffffff; }
  .btn-primary:hover, .btn-primary:focus {
    color: #ffffff;
    box-shadow: unset; }
  .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: unset; }
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff; }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    opacity: 1; }
    .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary:disabled:hover, .btn-primary:disabled:focus {
      color: #ffffff;
      box-shadow: unset; }

/*owl carousel btn*/
.owl-theme .owl-nav {
  margin-top: 0 !important; }
  .owl-theme .owl-nav [class*="owl-"] {
    position: absolute;
    left: 40px;
    top: 45%;
    font-size: 30px !important;
    width: 60px;
    height: 60px;
    text-align: center;
    color: #ffffff !important;
    line-height: 50px !important;
    margin: 0;
    background: rgba(0, 0, 0, 0.5) !important;
    border-radius: 30px !important;
    transition: 0.4s; }
  .owl-theme .owl-nav [class*="owl-"]:hover {
    color: #ffffff !important; }
  .owl-theme .owl-nav .owl-next {
    left: auto;
    right: 40px;
    cursor: pointer; }
  .owl-theme .owl-nav .owl-prev {
    transform: rotate(180deg);
    line-height: 50px;
    cursor: pointer; }

.owl-theme .owl-dots {
  line-height: 0.1;
  margin-top: 0 !important;
  position: relative;
  bottom: 90px;
  display: none; }
  .owl-theme .owl-dots .owl-dot span {
    width: 15px !important;
    height: 15px !important;
    margin: 0 5px !important;
    background: #d7d7d7 !important;
    border-radius: 50% !important;
    transition: 0.4s !important; }

/*form control*/
.form-control {
  height: 45px;
  padding: 15px;
  font-size: 15px;
  color: #000000;
  background-color: #eeeeee;
  border: none;
  border-radius: 0; }

.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #eeeeee;
  outline: 0;
  box-shadow: unset; }

/*section title*/
.section-title {
  text-align: center;
  margin-bottom: 30px;
  margin-top: -8px;
  font-family: "Ubuntu", sans-serif; }
  .section-title span {
    display: block;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700; }
  .section-title h2 {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 15px; }
    .section-title h2 b {
      font-weight: 700; }
  .section-title p {
    font-size: 16px;
    max-width: 535px;
    margin: 0 auto; }

.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 8em;
  height: 8em;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s; }

.loader--hide {
  opacity: 0; }

@keyframes load8 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/*================================================
Navbar Area CSS
=================================================*/
.navbar {
  position: fixed;
  width: 100%;
  height: auto;
  padding: 0;
  left: 0;
  top: 0;
  background-color: transparent !important;
  z-index: 999; }
  .navbar.navbar-light.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 0;
    background-color: #ffffff !important;
    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown; }

.navbar-light .navbar-brand {
  color: #ffffff;
  font-size: 28px;
  font-weight: 500;
  font-family: "Ubuntu", sans-serif;
  cursor: pointer;
  width: 160px; }
  .navbar-light .navbar-brand span {
    font-weight: 700; }
  .navbar-light .navbar-brand:hover {
    color: #ffffff; }

.navbar-light .navbar-nav .nav-item {
  padding: 0 14px; }
  .navbar-light .navbar-nav .nav-item .nav-link {
    color: #ffffff;
    padding: 0;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer; }
    .navbar-light .navbar-nav .nav-item .nav-link span {
      position: relative;
      display: inline-block;
      width: 35px;
      height: 3px;
      right: 0;
      top: -3px;
      transition: 0.4s; }
      .navbar-light .navbar-nav .nav-item .nav-link span::before, .navbar-light .navbar-nav .nav-item .nav-link span:after {
        content: "";
        width: 25px;
        height: 3px;
        position: absolute;
        right: 0;
        transition: 0.4s; }
      .navbar-light .navbar-nav .nav-item .nav-link span::before {
        top: -7px; }
      .navbar-light .navbar-nav .nav-item .nav-link span::after {
        bottom: -7px; }
    .navbar-light .navbar-nav .nav-item .nav-link:hover span::before,
    .navbar-light .navbar-nav .nav-item .nav-link:hover span::after {
      width: 35px; }
  .navbar-light .navbar-nav .nav-item:last-child {
    padding-right: 0; }

.navbar-light.is-sticky .navbar-brand {
  color: #222222; }

.navbar-light.is-sticky .navbar-nav .nav-item .nav-link {
  color: #222222; }

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  height: 100vh;
  overflow: hidden;
  background-color: #dddddd;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1; }
  .main-banner::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
    z-index: -1; }
  .main-banner::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-image: url(../../assets/img/dots.png);
    background-repeat: repeat;
    z-index: -1; }
  .main-banner .scroll_down {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 50px;
    width: 30px;
    z-index: 5;
    bottom: 30px;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 20px;
    cursor: pointer;
    animation: movebounce 3s linear infinite;
    outline: none; }
    .main-banner .scroll_down::before {
      position: absolute;
      top: 10px;
      left: 50%;
      content: "";
      width: 6px;
      height: 6px;
      margin-left: -3px;
      background-color: #fff;
      border-radius: 100%;
      animation: scroll_down_btn-animation 2s infinite;
      box-sizing: border-box; }

button.modal-video-close-btn {
  cursor: pointer; }

.item-bg-one {
  background-image: url(../../assets/img/test-banner1.jpg); }

.item-bg-two {
  background-image: url(../../assets/img/test-banner2.jpg); }

.item-bg-three {
  background-image: url(../../assets/img/test-banner3.jpg); }

.item-bg-four {
  background-image: url(../../assets/img/test-banner4.jpg); }

.item-bg-five {
  background-image: url(../../assets/img/test-banner5.jpg); }

.hero-content {
  text-align: center;
  max-width: 940px;
  margin: 50px auto 0; }
  .hero-content h5 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 300;
    margin: 0; }
  .hero-content h1 {
    color: #ffffff;
    font-size: 70px;
    font-weight: 700;
    margin: 15px 0;
    text-transform: uppercase; }
  .hero-content p {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 30px; }
  .hero-content .video-btn {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff !important;
    margin-left: 7px;
    text-transform: capitalize;
    background: transparent;
    border: none;
    cursor: pointer; }
    .hero-content .video-btn i {
      font-size: 38px;
      position: relative;
      top: 11px;
      margin-right: 7px; }
  .hero-content .btn {
    margin-right: 7px; }

.opacityZero {
  opacity: 0; }

.opacityOne {
  opacity: 1; }

.creative-box1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0; }
  .creative-box1::before {
    content: "";
    border-right: 50vw solid #ffffff;
    border-top: 60px solid transparent;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 0;
    width: 0; }
  .creative-box1::after {
    content: "";
    position: absolute;
    border-left: 50vw solid #ffffff;
    border-top: 60px solid transparent;
    bottom: 0;
    height: 0;
    left: 0;
    width: 0; }

.animation-box1 {
  position: absolute;
  left: 100px;
  bottom: 100px;
  animation: movebounce 3s linear infinite; }

.animation-box2 {
  position: absolute;
  right: 150px;
  top: 150px;
  animation: movebounce 3s linear infinite; }

.animation-box3 {
  position: absolute;
  left: 100px;
  top: 100px;
  animation: movebounce 3s linear infinite; }

.animation-box4 {
  position: absolute;
  right: -260px;
  bottom: -260px;
  animation: movebounce 3s linear infinite; }

.animation-box5 {
  position: absolute;
  right: -150px;
  bottom: -150px;
  z-index: -1; }

.animation-box6 {
  position: absolute;
  right: 5px;
  bottom: 15px;
  z-index: -1;
  animation: movebounce 3s linear infinite; }

.animation-box7 {
  position: absolute;
  left: 50%;
  bottom: 50px;
  z-index: -1;
  animation: movebounce 3s linear infinite; }

@keyframes movebounce {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(15px); }
  100% {
    transform: translateY(0px); } }

@keyframes moveleftbounce {
  0% {
    transform: translateX(0px); }
  50% {
    transform: translateX(15px); }
  100% {
    transform: translateX(0px); } }

@keyframes scroll_down_btn-animation {
  0% {
    transform: translate(0, 0);
    opacity: 0; }
  40% {
    opacity: 1; }
  80% {
    transform: translate(0, 20px);
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes scroll_down_btn-animation {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 0; }
  40% {
    opacity: 1; }
  80% {
    -webkit-transform: translate(0, 20px);
    transform: translate(0, 20px);
    opacity: 0; }
  100% {
    opacity: 0; } }

.wave_box {
  height: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0; }
  .wave_box .wave {
    background: url(../../assets/img/wave.png) repeat-x;
    position: absolute;
    bottom: 0;
    width: 6400px;
    left: 0;
    height: 90px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0); }
    .wave_box .wave:nth-of-type(2) {
      bottom: -30px;
      animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
      opacity: 1; }

@keyframes wave {
  0% {
    margin-left: 0; }
  100% {
    margin-left: -1600px; } }

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0); }
  50% {
    transform: translate3d(0, 5px, 0); } }

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
  padding-top: 50px;
  font-family: "Ubuntu", sans-serif;
  padding-bottom: 50px;
  position: relative;
  position: relative; }
  .subscribe-area p {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 24px; }
  .subscribe-area .form-control {
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 30px;
    color: #ffffff; }
    .subscribe-area .form-control::placeholder {
      color: #ffffff; }
  .subscribe-area button {
    position: absolute;
    right: 10px;
    top: 0;
    height: 45px;
    width: 75px;
    background: #ffffff;
    border: none;
    border-radius: 30px;
    font-size: 25px;
    cursor: pointer;
    line-height: 50px; }
  .subscribe-area .validation-danger,
  .subscribe-area .validation-success {
    color: #ffffff;
    margin-top: 10px; }
  .subscribe-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 25px;
    background-image: url(../../assets/img/border.png);
    background-position: center center;
    background-repeat: repeat;
    background-size: contain;
    transform: rotate(540deg); }

/*================================================
Welcome Area CSS
=================================================*/
.single-box {
  text-align: center;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
  padding: 30px 20px;
  background: #ffffff;
  position: relative;
  transition: 0.4s; }
  .single-box i {
    font-size: 50px;
    color: #999999;
    transition: 0.4s; }
  .single-box h3 {
    color: #212529;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 20px; }
  .single-box p {
    margin-bottom: 0; }
  .single-box::before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    border-radius: 0 0 5px 5px;
    left: 0;
    bottom: 0;
    transition: 0.4s; }
  .single-box:hover::before {
    width: 100%; }
  .single-box:hover {
    transform: translateY(-10px); }

/*================================================
About Area CSS
=================================================*/
.about-area {
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .about-area .row {
    position: relative; }
  .about-area .animation-box2 {
    right: 80px;
    top: 50px; }

.about-image {
  position: absolute;
  left: -90px;
  top: -50px; }

.about-title h3 {
  font-size: 30px;
  margin: 0 0 10px;
  font-weight: 400; }

.about-title h5 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 300;
  margin: 0 0 15px; }
  .about-title h5 span {
    font-weight: 400; }

.about-title p {
  margin-bottom: 0; }

.our-skill {
  margin-top: 25px; }
  .our-skill .single-progress .progress-title {
    font-size: 16px;
    margin: 0 0 8px; }
  .our-skill .single-progress .progress {
    height: 10px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: none;
    margin-bottom: 20px;
    overflow: visible; }
    .our-skill .single-progress .progress .progress-bar {
      box-shadow: none;
      position: relative;
      border-radius: 10px;
      animation: animate-positive 2s; }
    .our-skill .single-progress .progress .progress-value {
      font-size: 15px;
      font-weight: 400;
      color: #222222;
      font-family: "Ubuntu", sans-serif;
      position: absolute;
      top: -28px;
      right: 0; }
  .our-skill .single-progress:nth-child(4) .progress {
    margin-bottom: 0; }

@-webkit-keyframes animate-positive {
  0% {
    width: 0; } }

@keyframes animate-positive {
  0% {
    width: 0; } }

.our-mission p {
  margin-bottom: 0;
  margin-top: 20px; }

.why-choose-us {
  text-align: center;
  background: #ffffff;
  margin-top: 20px; }
  .why-choose-us .single-choose {
    padding: 20px 15px; }
    .why-choose-us .single-choose i {
      font-size: 35px;
      color: #777777;
      transition: 0.4s; }
    .why-choose-us .single-choose h5 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
      margin-top: 8px; }
  .why-choose-us .col-lg-4:nth-child(1) .single-choose {
    border-bottom: 1px solid #eeeeee; }
  .why-choose-us .col-lg-4:nth-child(2) .single-choose {
    border-bottom: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee; }
  .why-choose-us .col-lg-4:nth-child(3) .single-choose {
    border-bottom: 1px solid #eeeeee; }
  .why-choose-us .col-lg-4:nth-child(5) .single-choose {
    border-right: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee; }

/*================================================
Services Area CSS
=================================================*/
.services-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-bottom: 70px;
  background-image: url(../../assets/img/bg.png);
  background-attachment: fixed;
  background-repeat: repeat; }

.single-services {
  background: #ffffff;
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  transition: 0.4s;
  text-align: center; }
  .single-services a {
    padding: 30px 20px;
    height: 100%;
    width: 100%; }
  .single-services img {
    width: 50px; }
  .single-services h3 {
    color: #212529;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 20px; }
  .single-services p {
    margin-bottom: 0; }
  .single-services .bg-number {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 54px;
    opacity: 0.25;
    transition: 0.4s; }
  .single-services:hover .bg-number {
    opacity: 1; }
  .single-services:hover {
    transform: translateY(-10px);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15); }

/*================================================
Team Area CSS
=================================================*/
.team-area {
  position: relative;
  overflow: hidden; }
  .team-area .animation-box4 {
    right: -300px;
    bottom: -300px; }

.single-team {
  text-align: center; }
  .single-team ul {
    margin: 0;
    list-style-type: none;
    display: inline-block;
    position: relative;
    top: -20px;
    padding: 6px 30px;
    border-radius: 30px; }
    .single-team ul li {
      display: inline-block; }
      .single-team ul li a {
        color: #ffffff;
        font-size: 15px;
        margin: 0 -3px;
        width: 33px;
        border-radius: 50%;
        border: 1px solid transparent;
        height: 33px;
        text-align: center;
        line-height: 33px; }
        .single-team ul li a:hover {
          border-color: #ffffff;
          background: #ffffff; }
  .single-team h3 {
    font-weight: 500;
    font-size: 20px;
    margin-top: 6px;
    margin-bottom: 6px; }
  .single-team span {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #999999; }

/*================================================
Portfolio Area CSS
=================================================*/
.portfolio-area {
  padding-bottom: 60px; }

.single-work {
  margin-bottom: 40px; }
  .single-work .work-image {
    display: block;
    position: relative; }
    .single-work .work-image::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      transition: 0.4s; }
    .single-work .work-image:hover::before, .single-work .work-image:focus::before {
      width: 100%; }
    .single-work .work-image .popup-btn {
      position: absolute;
      left: 0;
      top: 50%;
      text-align: center;
      right: 0;
      cursor: pointer;
      color: #ffffff;
      border: 2px solid #ffffff;
      width: 45px;
      margin: 0 auto;
      height: 45px;
      line-height: 45px;
      border-radius: 50%;
      margin-top: 0;
      opacity: 0;
      visibility: hidden; }
      .single-work .work-image .popup-btn:hover {
        background: #ffffff; }
    .single-work .work-image:hover .popup-btn,
    .single-work .work-image:focus .popup-btn {
      opacity: 1;
      visibility: visible;
      margin-top: -15px; }
  .single-work h3 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    margin: 25px 0 0;
    line-height: 12px; }

/*================================================
Features Area CSS
=================================================*/
.features-area {
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .features-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    opacity: 0.2;
    width: 100%;
    background: url(../../assets/img/white-waves.png); }

.features-img img {
  border-radius: 10px; }

.single-features {
  position: relative;
  margin-bottom: 30px;
  padding-left: 90px; }
  .single-features .icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    font-size: 30px;
    transition: 0.4s; }
  .single-features:hover .icon {
    background: transparent;
    color: #ffffff; }
  .single-features h3 {
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 15px; }
  .single-features p {
    color: #fff;
    margin-bottom: 0;
    font-weight: 400; }
  .single-features:nth-child(3) {
    margin-bottom: 0; }

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
  position: relative;
  overflow: hidden; }
  .feedback-area .feedback-container a {
    width: 100%; }

.single-feedback {
  background: #ffffff;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.06);
  padding: 20px;
  border-radius: 5px;
  margin-top: 25px;
  z-index: 1;
  position: relative;
  transition: 0.4s; }
  .single-feedback .img {
    background-size: contain;
    width: 100%;
    height: 235px;
    background-repeat: no-repeat;
    background-position: center; }
  .single-feedback h4,
  .single-feedback small {
    color: #212529;
    transition: 0.4s; }
  .single-feedback .client-info {
    position: relative;
    padding-left: 118px;
    margin-bottom: 20px; }
    .single-feedback .client-info .img {
      position: absolute;
      left: 0;
      top: -45px;
      width: 100px; }
      .single-feedback .client-info .img img {
        border-radius: 0 20px 0 0; }
    .single-feedback .client-info h4 {
      font-size: 20px;
      font-weight: 500;
      -moz-transition: 0.4s;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
    .single-feedback .client-info span {
      display: block;
      font-size: 14px;
      color: #828893;
      margin-top: 8px;
      transition: 0.4s; }
  .single-feedback p {
    transition: 0.4s;
    margin-bottom: 0;
    overflow: hidden; }
  .single-feedback:hover p,
  .single-feedback:hover h4,
  .single-feedback:hover small {
    color: #fff; }
  .single-feedback i {
    font-size: 104px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #6f8ba4;
    z-index: -1;
    margin-top: -6px;
    margin-left: -70px;
    opacity: 0.07;
    transition: 0.4s; }
  .single-feedback:hover i {
    color: #ffffff;
    opacity: 0.14; }

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
  padding-bottom: 70px; }

.single-blog-post {
  margin-bottom: 30px; }
  .single-blog-post .post-thumbnail {
    overflow: hidden; }
    .single-blog-post .post-thumbnail a {
      display: block; }
      .single-blog-post .post-thumbnail a img {
        transition: 0.4s; }
  .single-blog-post:hover .post-thumbnail a img {
    transform: scale(1.3); }
  .single-blog-post .post-content {
    margin-top: 25px; }
    .single-blog-post .post-content h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 15px; }
      .single-blog-post .post-content h3 a {
        color: #000000; }
    .single-blog-post .post-content .read-more {
      font-size: 15px;
      color: #777777; }

/*================================================
Work With Us Area CSS
=================================================*/
.work-with-us {
  z-index: 1;
  text-align: center;
  position: relative; }
  .work-with-us::before {
    content: "";
    position: absolute;
    left: 0;
    opacity: 0.2;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/img/regal.png);
    z-index: -1; }
  .work-with-us h3 {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 12px;
    margin-top: -7px; }
  .work-with-us span {
    font-size: 20px;
    color: #ffffff;
    display: block;
    margin-bottom: 25px; }
  .work-with-us .btn-primary {
    color: #71c21b;
    background-color: #ffffff;
    border: 1px solid #ffffff; }
    .work-with-us .btn-primary:hover, .work-with-us .btn-primary:focus {
      color: #ffffff;
      background: transparent; }

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  text-align: center;
  overflow: hidden;
  position: relative; }
  .partner-area .item a {
    display: block;
    position: relative;
    z-index: 1; }
  .partner-area .owl-carousel .owl-item img {
    width: 140px; }

/*================================================
Contact Area CSS
=================================================*/
.contact-box {
  min-height: 133px;
  padding-left: 115px;
  padding-right: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative; }
  .contact-box .icon {
    position: absolute;
    left: 25px;
    top: 30px;
    width: 70px;
    height: 70px;
    line-height: 68px;
    font-size: 35px;
    text-align: center;
    border-radius: 50%;
    transition: 0.4s; }
  .contact-box:hover .icon {
    color: #ffffff; }
  .contact-box h4 {
    font-size: 18px;
    margin-bottom: 12px; }
  .contact-box p {
    margin: 0;
    line-height: 25px;
    font-weight: 400; }
    .contact-box p a {
      color: #777777; }

.get-in-touch {
  margin-top: 40px; }
  .get-in-touch h3 {
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eeeeee; }
  .get-in-touch p {
    margin-bottom: 20px; }
  .get-in-touch ul {
    padding: 0;
    margin: 0;
    list-style-type: none; }
    .get-in-touch ul li {
      display: inline-block; }
      .get-in-touch ul li a {
        margin-right: 5px; }

#contactForm .form-control {
  background: transparent;
  border: 1px solid #dbdbdb;
  border-radius: 5px; }

#contactForm .list-unstyled li {
  color: red; }

#contactForm #msgSubmit {
  margin: 0; }
  #contactForm #msgSubmit.text-left {
    margin-top: 20px;
    font-size: 18px; }

.sendingMsg {
  background-color: #f0ad4e;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 40px; }
  .sendingMsg span {
    color: #fff; }

.contactMsg {
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 40px; }
  .contactMsg span {
    color: #fff; }

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  padding: 30px 0;
  background: #121314; }
  .footer-area h6 {
    color: #ddd; }
  .footer-area p {
    font-size: 14px;
    margin-bottom: 0;
    color: #d5d5d5; }
  .footer-area ul {
    text-align: right;
    list-style-type: none;
    padding: 0;
    margin: 0; }
  .footer-area li {
    display: inline-block;
    position: relative;
    font-size: 14px;
    padding-left: 17px; }
    .footer-area li::before {
      content: "";
      position: absolute;
      left: 5px;
      top: 5px;
      background: #ffffff;
      width: 2px;
      height: 12px; }
    .footer-area li:first-child::before {
      display: none; }
    .footer-area li a {
      color: #d5d5d5; }

/*================================================
Right Side Modal CSS
=================================================*/
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 520px;
  height: 100%;
  transform: translate3d(0%, 0, 0); }
  .modal.right .modal-dialog .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0;
    border: none; }
    .modal.right .modal-dialog .modal-content .modal-body {
      padding: 20px; }
      .modal.right .modal-dialog .modal-content .modal-body p {
        margin-top: 20px; }
      .modal.right .modal-dialog .modal-content .modal-body ul {
        padding: 0;
        margin: 0;
        list-style-type: none; }
      .modal.right .modal-dialog .modal-content .modal-body .modal-contact-info {
        margin-top: 30px;
        margin-bottom: 20px; }
        .modal.right .modal-dialog .modal-content .modal-body .modal-contact-info li {
          position: relative;
          padding-left: 45px;
          font-size: 18px;
          margin-bottom: 25px; }
          .modal.right .modal-dialog .modal-content .modal-body .modal-contact-info li b {
            display: block; }
          .modal.right .modal-dialog .modal-content .modal-body .modal-contact-info li i {
            position: absolute;
            left: 0;
            top: 0;
            font-size: 35px; }
      .modal.right .modal-dialog .modal-content .modal-body .social-links li {
        display: inline-block; }
        .modal.right .modal-dialog .modal-content .modal-body .social-links li a {
          margin-right: 10px;
          font-size: 18px; }
      .modal.right .modal-dialog .modal-content .modal-body .logo {
        width: 200px; }
        .modal.right .modal-dialog .modal-content .modal-body .logo a {
          color: #283a5e;
          text-transform: uppercase;
          font-size: 30px;
          font-weight: 900; }
    .modal.right .modal-dialog .modal-content .modal-header {
      border-bottom-color: transparent;
      background-color: transparent;
      padding: 20px 30px 0; }
      .modal.right .modal-dialog .modal-content .modal-header .close {
        color: #000000;
        font-size: 35px;
        outline: 0 !important;
        transition: 0.4s; }
        .modal.right .modal-dialog .modal-content .modal-header .close:not(:disabled):not(.disabled):focus, .modal.right .modal-dialog .modal-content .modal-header .close:not(:disabled):not(.disabled):hover {
          text-decoration: none;
          opacity: 1; }

.modal.right.fade .modal-dialog {
  right: -420px;
  transition: opacity 0.3s linear, right 0.3s ease-out; }

.modal.right.fade.show .modal-dialog {
  right: 0; }

/*================================================
Got Top CSS
=================================================*/
.ScrollUpButton__Container.ScrollUpButton__Toggled:hover {
  transition: 0.4s !important; }

/*================================================
Internal Page
=================================================*/
.internal-page {
  height: 100%; }
  .internal-page .main-container {
    background-color: #fff;
    position: relative;
    min-height: 100%; }
  .internal-page section {
    padding-top: 50px;
    padding-bottom: 320px; }
  .internal-page .navbar {
    position: inherit; }
    .internal-page .navbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
      color: #fff;
      opacity: 0.6; }
    .internal-page .navbar.navbar-light .navbar-nav .nav-item .nav-link span {
      background: #fff; }
      .internal-page .navbar.navbar-light .navbar-nav .nav-item .nav-link span::before, .internal-page .navbar.navbar-light .navbar-nav .nav-item .nav-link span:after {
        background: #fff; }
  .internal-page .pagination {
    margin-top: 20px; }
    .internal-page .pagination li {
      cursor: pointer;
      border-radius: 4px; }
      .internal-page .pagination li:hover {
        background-color: #f3f3f3; }
      .internal-page .pagination li a {
        padding: 0 10px; }
      .internal-page .pagination li.active a {
        color: #fff !important; }
  .internal-page .footer-area {
    bottom: 0;
    width: 100%; }

/*================================================
News Page
=================================================*/
.news-page .news-aside-list {
  background-color: #f3f3f3;
  padding: 5px 8px; }
  .news-page .news-aside-list .new-aside-item {
    cursor: pointer; }
    .news-page .news-aside-list .new-aside-item:hover {
      opacity: 0.7; }
    .news-page .news-aside-list .new-aside-item p {
      overflow: hidden; }

.news-page .news-main .news-title {
  color: #005b9f;
  margin-bottom: 0; }

/*================================================
Plans Page
=================================================*/
.plans-page .plans-banner {
  margin-bottom: 30px; }

.plans-page .plans-aside-list .plan-aside-item {
  background-color: #f3f3f3;
  margin-bottom: 4px; }
  .plans-page .plans-aside-list .plan-aside-item.react-sanfona-item-expanded, .plans-page .plans-aside-list .plan-aside-item:hover {
    color: #fff;
    transition: 0.5s; }
  .plans-page .plans-aside-list .plan-aside-item h3 {
    font-family: Ubuntu;
    font-size: 1.2rem;
    padding: 8px 10px; }
  .plans-page .plans-aside-list .plan-aside-item .react-sanfona-item-body-wrapper {
    padding: 5px 5px 10px; }
    .plans-page .plans-aside-list .plan-aside-item .react-sanfona-item-body-wrapper .plan-aside-subitem {
      cursor: pointer;
      padding: 2px 0 2px 30px; }
      .plans-page .plans-aside-list .plan-aside-item .react-sanfona-item-body-wrapper .plan-aside-subitem:hover {
        transition: 0.2s; }

/*================================================
Seguro Viagem Page
=================================================*/
.travel-insurance-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 3em;
  padding-bottom: calc(190px + 3em); }
  .travel-insurance-page .travel-insurance-files {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap; }
  .travel-insurance-page .travel-insurance-frame {
    background-color: #fff;
    border-radius: 0.5em;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    margin: 10px;
    display: flex;
    align-items: stretch;
    max-width: 535px; }
    @media only screen and (max-width: 1199px) {
      .travel-insurance-page .travel-insurance-frame {
        max-width: 100%; } }
    .travel-insurance-page .travel-insurance-frame .icon-container {
      border-right: 1px solid #f1f1f1;
      height: 100%;
      padding: 0 2em;
      display: flex;
      align-items: center;
      justify-content: center; }
      .travel-insurance-page .travel-insurance-frame .icon-container i {
        font-size: 2rem;
        color: #999;
        transition: 0.3s; }
    .travel-insurance-page .travel-insurance-frame .file-information div {
      padding: 0.3em 0.5em; }
    .travel-insurance-page .travel-insurance-frame .file-information a {
      color: #212529; }
    .travel-insurance-page .travel-insurance-frame:hover .icon-container i {
      color: #005b9f; }
    .travel-insurance-page .travel-insurance-frame:hover .file-information a {
      color: #005b9f; }
    .travel-insurance-page .travel-insurance-frame .file-content {
      display: flex;
      flex-direction: column;
      height: 100%; }
    .travel-insurance-page .travel-insurance-frame.frame-simple .file-description {
      display: none; }
    .travel-insurance-page .travel-insurance-frame.frame-simple .file-information {
      display: flex;
      flex-direction: column; }
      .travel-insurance-page .travel-insurance-frame.frame-simple .file-information div {
        border-bottom: 1px solid #f1f1f1; }
      .travel-insurance-page .travel-insurance-frame.frame-simple .file-information div:last-child {
        border-bottom: transparent; }
    .travel-insurance-page .travel-insurance-frame.frame-complete .file-description {
      padding: 0.3em 0.5em; }
      .travel-insurance-page .travel-insurance-frame.frame-complete .file-description small {
        font-size: 0.75rem; }
    .travel-insurance-page .travel-insurance-frame.frame-complete .file-information {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #f1f1f1; }
      .travel-insurance-page .travel-insurance-frame.frame-complete .file-information div {
        border-left: 1px solid #f1f1f1; }
      .travel-insurance-page .travel-insurance-frame.frame-complete .file-information div:first-child {
        border-left: transparent;
        margin-right: auto; }

/*================================================
União Seguradora Page
=================================================*/
.uniao-seguradora-page .btn-primary {
  background-color: #005b9f;
  border-color: #005b9f; }
  .uniao-seguradora-page .btn-primary:hover, .uniao-seguradora-page .btn-primary:focus {
    background: #1d75b8;
    border-color: #1d75b8; }
  .uniao-seguradora-page .btn-primary:not(:disabled):not(.disabled).active, .uniao-seguradora-page .btn-primary:not(:disabled):not(.disabled):active,
  .show > .uniao-seguradora-page .btn-primary.dropdown-toggle {
    background: #005b9f;
    border-color: #005b9f; }
  .uniao-seguradora-page .btn-primary.disabled, .uniao-seguradora-page .btn-primary:disabled {
    background-color: #005b9f;
    border-color: #005b9f; }
    .uniao-seguradora-page .btn-primary.disabled:hover, .uniao-seguradora-page .btn-primary.disabled:focus, .uniao-seguradora-page .btn-primary:disabled:hover, .uniao-seguradora-page .btn-primary:disabled:focus {
      background: #1d75b8;
      border-color: #1d75b8; }

.uniao-seguradora-page .owl-theme .owl-nav [class*="owl-"]:hover {
  background: #005b9f !important; }

.uniao-seguradora-page .owl-theme .owl-dots .owl-dot:hover span,
.uniao-seguradora-page .owl-theme .owl-dots .owl-dot.active span {
  background: #005b9f !important; }

.uniao-seguradora-page .section-title span {
  color: #005b9f; }

.uniao-seguradora-page .loader {
  border: 1.1em solid #005b9f;
  border-left: 1.1em solid #1d75b8; }

.uniao-seguradora-page .navbar-light .navbar-brand span {
  color: #005b9f; }

.uniao-seguradora-page .navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #71acda; }

.uniao-seguradora-page .navbar-light .navbar-nav .nav-item .nav-link span {
  background: #71acda; }
  .uniao-seguradora-page .navbar-light .navbar-nav .nav-item .nav-link span::before, .uniao-seguradora-page .navbar-light .navbar-nav .nav-item .nav-link span:after {
    background: #71acda; }

.uniao-seguradora-page .navbar-light .navbar-nav .nav-item .nav-link.active {
  color: #71acda; }

.uniao-seguradora-page .navbar-light .navbar-nav .nav-item.active .nav-link {
  color: #71acda; }

.uniao-seguradora-page .navbar-light.is-sticky .navbar-nav .nav-item .nav-link:hover {
  color: #005b9f; }

.uniao-seguradora-page .navbar-light.is-sticky .navbar-nav .nav-item .nav-link.active {
  color: #005b9f; }

.uniao-seguradora-page .navbar-light.is-sticky .navbar-nav .nav-item.active .nav-link {
  color: #005b9f; }

.uniao-seguradora-page .hero-content h1 span {
  color: #71acda; }

.uniao-seguradora-page .hero-content .video-btn:hover, .uniao-seguradora-page .hero-content .video-btn:focus {
  color: #005b9f !important; }

.uniao-seguradora-page .subscribe-area {
  background: #005b9f; }
  .uniao-seguradora-page .subscribe-area button {
    color: #005b9f; }

.uniao-seguradora-page .single-box:hover i {
  color: #005b9f; }

.uniao-seguradora-page .single-box::before {
  background: #005b9f; }

.uniao-seguradora-page .about-title h5 span {
  color: #005b9f; }

.uniao-seguradora-page .our-skill .single-progress .progress .progress-bar {
  background: #005b9f; }

.uniao-seguradora-page .why-choose-us .single-choose:hover i {
  color: #005b9f; }

.uniao-seguradora-page .single-services:hover i {
  color: #005b9f; }

.uniao-seguradora-page .single-services .bg-number {
  color: #005b9f; }

.uniao-seguradora-page .single-team ul {
  background: #005b9f; }
  .uniao-seguradora-page .single-team ul li a:hover {
    color: #005b9f; }

.uniao-seguradora-page .single-work .work-image::before {
  background: #005b9f; }

.uniao-seguradora-page .single-work .work-image .popup-btn:hover {
  color: #005b9f; }

.uniao-seguradora-page .features-area {
  background: #005b9f; }

.uniao-seguradora-page .single-features .icon {
  color: #005b9f; }

.uniao-seguradora-page .single-feedback:hover {
  background: #005b9f; }

.uniao-seguradora-page .tab-slider-nav .tab-slider .nav-tabs a.active {
  background: #005b9f; }

.uniao-seguradora-page .work-with-us {
  background-color: #005b9f; }

.uniao-seguradora-page .contact-box .icon {
  color: #005b9f;
  border: 1px solid #005b9f; }

.uniao-seguradora-page .contact-box:hover .icon {
  background: #005b9f;
  color: #fff; }

.uniao-seguradora-page .contact-box p a:hover {
  color: #005b9f; }

.uniao-seguradora-page .get-in-touch ul li a {
  color: #005b9f; }

.uniao-seguradora-page .contactMsg {
  background-color: #005b9f; }

.uniao-seguradora-page .footer-area li a:hover {
  color: #005b9f; }

.uniao-seguradora-page.modal.right .modal-dialog .modal-content .modal-body .modal-contact-info li i {
  color: #005b9f; }

.uniao-seguradora-page.modal.right .modal-dialog .modal-content .modal-body .social-links li a {
  color: #005b9f; }

.uniao-seguradora-page.modal.right .modal-dialog .modal-content .modal-body .logo a span {
  color: #005b9f; }

.uniao-seguradora-page.modal.right .modal-dialog .modal-content .modal-header .close:not(:disabled):not(.disabled):focus, .uniao-seguradora-page.modal.right .modal-dialog .modal-content .modal-header .close:not(:disabled):not(.disabled):hover {
  color: #005b9f; }

.uniao-seguradora-page .ScrollUpButton__Container.ScrollUpButton__Toggled:hover {
  background-color: #005b9f !important; }

/*================================================
União Seguradora Internal Page
=================================================*/
.internal-page.uniao-seguradora-page .navbar {
  background-color: #005b9f !important; }

.internal-page.uniao-seguradora-page .pagination li a {
  color: #005b9f; }

.internal-page.uniao-seguradora-page .pagination li.active {
  background-color: #005b9f; }

/*================================================
União Seguradora Plans Page
=================================================*/
.plans-page.uniao-seguradora-page .plans-aside-list .plan-aside-item.react-sanfona-item-expanded, .plans-page.uniao-seguradora-page .plans-aside-list .plan-aside-item:hover {
  background-color: #005b9f; }

.plans-page.uniao-seguradora-page .plans-aside-list .plan-aside-item .react-sanfona-item-body-wrapper .plan-aside-subitem:hover, .plans-page.uniao-seguradora-page .plans-aside-list .plan-aside-item .react-sanfona-item-body-wrapper .plan-aside-subitem.active {
  background-color: #1d75b8; }

/*================================================
Component Section Certificate Page
=================================================*/
.section-certificate-page:hover {
  background-color: #71abd778 !important; }

@media (max-width: 992px) {
  .section-certificate-page {
    margin-top: 20px !important; } }

/*================================================
Component Certificate
=================================================*/
.container-fluid-certificate-component {
  display: grid;
  background-color: #005a9f78;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: minmax(380px, max-content);
  justify-content: start;
  align-items: center;
  position: relative;
  column-gap: 30px;
  row-gap: 0px; }
  .container-fluid-certificate-component .content-certificate-component {
    color: white;
    flex-direction: column;
    align-items: center;
    grid-column: 2; }
    .container-fluid-certificate-component .content-certificate-component .title-certificate-component {
      font-size: 50px;
      line-height: 70%;
      color: white; }

.form_certificate_component {
  /* Estilos adicionais para compatibilidade com diferentes navegadores */ }
  .form_certificate_component .form-control {
    border: 1px solid #bdbdbd !important;
    border-radius: 8px;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important; }
  .form_certificate_component .form-control-no-spinner::-webkit-inner-spin-button,
  .form_certificate_component .form-control-no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .form_certificate_component .form-control-no-spinner {
    -moz-appearance: textfield; }

/* Adjust for smaller screens */
@media (max-width: 768px) {
  .container-fluid-certificate-component {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important; }
  .title-certificate-component {
    margin-right: 20px;
    font-size: 45px !important;
    color: white; } }

.partners-main-container {
  background-color: #f1f1f1;
  position: relative;
  min-height: 100%; }
  .partners-main-container .partners_represets_container {
    display: flex;
    width: 100%;
    max-height: 954px;
    flex-direction: column;
    margin-top: 30px;
    align-items: center;
    margin-bottom: 33px; }
    .partners-main-container .partners_represets_container h4 {
      font-weight: 800; }
    .partners-main-container .partners_represets_container .partners_represets_header {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 20px; }
      .partners-main-container .partners_represets_container .partners_represets_header p {
        font-size: 25px;
        margin-bottom: 0;
        color: #000;
        font-weight: 400; }
      .partners-main-container .partners_represets_container .partners_represets_header span {
        font-size: 30px;
        color: #000;
        font-weight: 700; }
    .partners-main-container .partners_represets_container .partners_represets_content {
      background-color: #fff;
      width: 80%;
      max-height: 520px;
      padding: 30px 100px 10px 100px;
      overflow-y: scroll; }
      .partners-main-container .partners_represets_container .partners_represets_content .partners_represent_inside_content {
        margin-top: 20px;
        margin-bottom: 20px; }
        .partners-main-container .partners_represets_container .partners_represets_content .partners_represent_inside_content .partners_represent_inside_content_header {
          display: flex;
          gap: 80px; }
          .partners-main-container .partners_represets_container .partners_represets_content .partners_represent_inside_content .partners_represent_inside_content_header p {
            font-size: 20px;
            color: #000;
            font-weight: 500; }
        .partners-main-container .partners_represets_container .partners_represets_content .partners_represent_inside_content .partners_represent_inside_content_local p {
          font-size: 20px;
          margin-bottom: 0; }

.schedule_config {
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 1000; }
